<template>
  <div class="container wrapper">
    <h1 class="pageHeader mt-12">Tell Your Talents</h1>
    <div class="my-8">Share and invite talent to join under agency profile</div>
    <div class="row my-5">
      <div class="col-md-6">
        <div style="width: 80%" class="py-10 px-8 grey-bg">
          <div class="pink-container text-center ml-2 mb-5">
            <p>3 Simple Steps</p>
          </div>
          <ol class="">
            <li>Copy and share the code</li>
            <li>Talent keys in code in the registration form</li>
            <li>Approve agency-talent link</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="row pb-12 mb-12">
      <div class="col-md-6 d-flex">
        <div style="width: 70%">
          <AppFormField
            outlined
            dark
            disabled
            hideDetails
            label="Code"
            v-model="me.company_code"
          />
        </div>
        <div class="px-3 align-self-center">
          <v-btn icon @click="copyText">
            <v-icon dark color="white"> mdi-content-copy </v-icon>
          </v-btn>
          <v-tooltip v-model="showTooltip" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                disabled
                icon
                v-bind="attrs"
                v-on="on"
                style="margin-left: -25px"
              >
              </v-btn>
            </template>
            <span>Copied!</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'CompanyCode',
    data() {
      return {
        showTooltip: false
      };
    },
    computed: {
      ...mapGetters({
        me: ['auth/me']
      })
    },
    methods: {
      async copyText() {
        console.log('copy');
        try {
          await navigator.clipboard.writeText(this.me.company_code);
          this.showTooltip = true;
          setTimeout(() => (this.showTooltip = false), 1000);
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  ::v-deep .v-input__append-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .clickable {
    cursor: pointer;
  }
  .grey-bg {
    background-color: #242424;
  }
  .pink-container {
    background-color: #ff2ff2;
    width: 45%;
    height: 35px;
    border-radius: 20px;
    margin: auto 0;
    padding: 5px;
  }
  ol {
    counter-reset: section;
    list-style-type: none;
  }
  li::before {
    counter-increment: section;
    content: counters(section, '.') ' ';
    margin-right: 5px;
    color: #ff2ff2;
  }
</style>
