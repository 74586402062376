import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container wrapper"},[_c('h1',{staticClass:"pageHeader mt-12"},[_vm._v("Tell Your Talents")]),_c('div',{staticClass:"my-8"},[_vm._v("Share and invite talent to join under agency profile")]),_vm._m(0),_c('div',{staticClass:"row pb-12 mb-12"},[_c('div',{staticClass:"col-md-6 d-flex"},[_c('div',{staticStyle:{"width":"70%"}},[_c('AppFormField',{attrs:{"outlined":"","dark":"","disabled":"","hideDetails":"","label":"Code"},model:{value:(_vm.me.company_code),callback:function ($$v) {_vm.$set(_vm.me, "company_code", $$v)},expression:"me.company_code"}})],1),_c('div',{staticClass:"px-3 align-self-center"},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.copyText}},[_c(VIcon,{attrs:{"dark":"","color":"white"}},[_vm._v(" mdi-content-copy ")])],1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"margin-left":"-25px"},attrs:{"disabled":"","icon":""}},'v-btn',attrs,false),on))]}}]),model:{value:(_vm.showTooltip),callback:function ($$v) {_vm.showTooltip=$$v},expression:"showTooltip"}},[_c('span',[_vm._v("Copied!")])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row my-5"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"py-10 px-8 grey-bg",staticStyle:{"width":"80%"}},[_c('div',{staticClass:"pink-container text-center ml-2 mb-5"},[_c('p',[_vm._v("3 Simple Steps")])]),_c('ol',{},[_c('li',[_vm._v("Copy and share the code")]),_c('li',[_vm._v("Talent keys in code in the registration form")]),_c('li',[_vm._v("Approve agency-talent link")])])])])])}]

export { render, staticRenderFns }